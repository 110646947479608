import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className="wrap__full">
      <div className="container">
        <div className="row intro-header">
          <div className="col-12">
            <h2 className="subheading">Looking for something?</h2>
            <h1>404 - file not found</h1>
            <h2 className='summary'>You seem to be looking for something that doesn't exist. Try using the main navigation.</h2>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default NotFoundPage
